<template>
	<div>
		<Form :id="id" @close="close" />
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { GET_FORM_DETAIL } from "../store/action-types"
import { RESET_FILTERS_FORM_LIST } from "../store/mutation-types"
const { mapMutations, mapActions } = createNamespacedHelpers("forms")

export default {
	name: "FormDetail",
	components: {
		Form: () => import("../components/Form"),
	},

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},
	created() {
		this.getFormDetail()
	},
	beforeDestroy() {
		this.RESET_FILTERS_FORM_LIST()
	},
	mounted() {
		const body = document.getElementsByTagName("body")[0]
		body.scrollTop = 0
	},
	methods: {
		...mapActions({ GET_FORM_DETAIL }),
		...mapMutations({ RESET_FILTERS_FORM_LIST }),
		async getFormDetail() {
			await this.GET_FORM_DETAIL({ id: this.id })
		},
		close() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss">
#technician-detail {
	padding-top: 16px;
}
</style>
